<script setup lang="ts">
import { Quote } from 'lucide-vue-next';
// import vue3StarRatings from 'vue3-star-ratings';
import { fromNowLocal } from 'assets/js/filters/dayjs';
import StarRating from '~/components/new/rating/StarRating/StarRating.vue';
import { iconColors } from 'assets/js/utils';
import type { Review } from '~/stores/Project.types';

const props = defineProps<{ reviewCard: Review }>();
const r = props.reviewCard.rating;

const comment = props.reviewCard.rating_comment;
const formattedComment = ref(comment);
const isCommentExpanded = ref(false);
const isCommentLong = computed(() => (comment ? comment.split(' ').length > 14 : false));
if (comment && comment.length) {
  if (!!isCommentLong.value && comment.split(' ')) formattedComment.value = comment.split(' ').slice(0, 14).join(' ') + '…';
}
</script>

<template>
  <div class="review-card">
    <div class="review-card__review">
      <div class="review-card__info">
        <star-rating
          v-model="r"
          :star-size="20"
          disable-click
          style="min-width: 125px"
        />
        <span class="review-card__author"> {{ reviewCard.user.name }} </span>
      </div>

      <p class="review-card__text">
        {{ formattedComment }}
        <br v-show="isCommentLong && !isCommentExpanded" />
        <span
          v-show="isCommentLong && !isCommentExpanded"
          class="review-card__text__show-button"
          @click="
            () => {
              formattedComment = comment;
              isCommentExpanded = true;
            }
          "
        >
          Показать полностью
        </span>
      </p>
    </div>
    <nuxt-link
      :to="`/projects/${reviewCard.id}`"
      class="review-card__project-card"
    >
      <div class="review-card__project__type">
        {{ reviewCard.type ? (reviewCard.type.at(-1) === 'й' ? reviewCard.type + ' проект' : reviewCard.type) : 'Проект' }}
      </div>
      <div class="review-card__project_title">
        {{ reviewCard.title }}
      </div>
    </nuxt-link>
    <span class="review-date">{{ fromNowLocal(reviewCard.rated_dt).replace(/ /g, '&nbsp;') }}</span>
  </div>
</template>

<style scoped lang="scss">
.review-card {
  // margin-bottom: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 20px;
  background: #fff;

  .review-card__project-card {
    margin-bottom: auto;

    padding: 12px;
    border-radius: 8px;
    background: $new-bg-theme-fade;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 12px;

    &:hover {
      box-shadow: $new-card-shadow;
    }

    .review-card__project_title {
      color: $new-fr-contrast;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 8px;
    }

    .review-card__project__type {
      color: $new-fr-theme;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      margin-bottom: 8px;

      &:first-letter {
        text-transform: capitalize;
      }
    }
  }

  .review-card__review {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .review-card__info {
      display: flex;
      flex-direction: row;
      gap: 12px;

      .review-card__rating {
      }

      .review-card__author {
        color: $new-fr-gray;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .review-card__text {
      // display: none;
      color: $new-fr-contrast;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;

      .review-card__text__show-button {
        color: $new-fr-theme;

        &:hover {
          cursor: pointer;
          text-decoration: underline 1px;
          text-underline-offset: 3px;
        }
      }
    }
  }

  .review-date {
    color: $new-fr-gray;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}
</style>
